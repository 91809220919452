import React from "react";
import { Redirect } from 'react-router-dom';
import {Elements, StripeProvider} from 'react-stripe-elements';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';

import DashboardTemplate from "../components/templates/DashboardTemplate";
import CheckoutForm from "../components/CheckoutForm";
import MixpanelOnload from "../components/MixpanelOnload";
import CreditCardCvcImage from "../img/cards/credit_card_cvc.png";
import CreditCardCvc2Image from "../img/cards/credit_card_cvc-2.png";
const moment = require('moment');

const useStyles = theme => ({
    '@global': {
    },
    paper: {
        padding: '8px',
        background: '#eeeeee',
    },
    'calculatedPrice': {
        fontSize: '2rem',
        fontWeight: 'bold',
    },
    sectionTitle: {
        background: '#dddddd',
    }
});

class SetupPayment extends React.Component {

    constructor() {
        super();
        this.state = {
            redirect: false,
            stripe: null,
            hasPlan: true,
            isTrial: true,
            trialEndDate: moment(new Date().getTime() + 14 * 24 * 3600 * 1000).format('YYYY-MM-DD'),
            trialRemains: 14,
            hasPaymentMethod: false,
            card: {
                brand: '',
                last4: '',
                exp_month: '',
                exp_year: ''
            },
            stripeEmail: '',
            nextPaymentDate: '',
            discountPrice: 30,
        };
        this.getAccount = this.getAccount.bind(this);
        this.setCompletePayment = this.setCompletePayment.bind(this);
        //this.setCompleteUpdateCard = this.setCompleteUpdateCard.bind(this);
    }

    componentDidMount() {
        fetch("/api/getStripeKey")
            .then(res => res.json())
            .then(data => {
                if (data.key) {
                    const stripeJs = document.createElement('script');
                    stripeJs.src = 'https://js.stripe.com/v3/';
                    stripeJs.async = true;
                    stripeJs.onload = () => {
                        // The setTimeout lets us pretend that Stripe.js took a long time to load
                        // Take it out of your production code!
                        setTimeout(() => {
                            this.setState({
                                stripe: window.Stripe(data.key),
                            });
                        }, 500);
                    }
                    document.body && document.body.appendChild(stripeJs);
                }
            });

        this.getAccount();
    }

    getAccount() {
        fetch('/api/account')
            .then(res => res.json())
            .then(data => {
                // TODO set flags
                console.log(data);
                if (data.planType) {
                    this.setState({card: data.card});
                    this.setState({stripeEmail: data.stripeEmail});
                    this.setState({nextPaymentDate: moment(data.nextPaymentTimestamp * 1000).format('YYYY-MM-DD')});
                    this.setState({hasPlan: true});
                    this.setState({planId: data.planId});
                    this.setState({isTrial: data.isTrial});
                    this.setState({trialEndDate: moment(data.trialEndTimestamp * 1000).format('YYYY-MM-DD')});
                    this.setState({hasPaymentMethod: data.hasPaymentMethod});
                    if (data.isOldPlan) {
                        if (data.planType === 1) {
                            this.setState({discountPrice: 35});
                        }
                        else {
                            this.setState({discountPrice: 0});
                        }
                    }
                    else {
                        if (data.unitCount >= 100){
                            this.setState({discountPrice: 2.4 * data.unitCount});
                        }
                        else {
                            this.setState({discountPrice: 3 * data.unitCount});
                        }
                    }
                }
                else {
                    this.setState({hasPlan: true});
                    this.setState({hasPaymentMethod: data.hasPaymentMethod || false});
                    this.setState({isTrial: true});
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({hasPlan: true});
                this.setState({hasPaymentMethod: false});
                this.setState({isTrial: true});
        })
    }

    trialAlert = () => {
        const {hasPlan, isTrial, trialEndDate} = this.state;
        console.log([hasPlan, isTrial, trialEndDate]);
        return (
            ((hasPlan && isTrial && trialEndDate) ? (
                    <Typography className="urgent-notice alert" color="primary" align="right">
                    You're free trial period ends on {trialEndDate}
                    </Typography>
                ) : (
                    <div></div>
                )
            )
        );
    }
    paymentAlert = () => {
        const {hasPlan, isTrial, trialEndDate, nextPaymentDate} = this.state;
        if (hasPlan) {
            if (isTrial && trialEndDate) {
                return <Typography className="urgent-notice alert" color="primary" align="right">
                Payment will start on {trialEndDate}.
                </Typography>
            }
            else {
                return <Typography className="urgent-notice alert" color="primary" align="right">
                New credit card will take effect on next payment date. {nextPaymentDate}
                </Typography>
            }
        }
        else {
            return <div></div>
        }
    }
    setCompletePayment = () => {
        console.log(this);
        this.setState({
            completePayment: true,
            redirect: true,
        })
    }
    paymentForm = () => {
        const { classes } = this.props;
        const { isTrial, hasPaymentMethod, planId, stripeEmail } = this.state;
        return (
            <StripeProvider stripe={this.state.stripe}>
            <Elements>
                <CheckoutForm
                    getAccount={this.getAccount}
                    setCompletePayment={this.setCompletePayment}
                    hasPaymentMethod={hasPaymentMethod}
                    planId={planId}
                    email={stripeEmail}
                    isTrial={isTrial}
                    canSkip={false}
                />
            </Elements>
            </StripeProvider>
        )
    }

    prependItems = () => {
        return <div>
            { this.trialAlert() }
            <Typography variant="h5" component="h2" align="center">
            Fill out the form below to change your payment setting.<br />
            If something is unclear? Talk to us by chat, <a href="https://www.getkiara.com/contact">form</a> or to hello@kiara.team
            </Typography>
        </div>
    }
    appendItems = () => {
        return <Grid container spacing={4} justify="space-evenly" alignItems="center"  justifyContent="center" sx={{ marginTop: '30px' }}>
            <Grid item md={8} sm={10} align="center">
            <Typography variant="h6" component="p" sx={{ marginBottom: '15px' }}>
            Where can I find CVC number?
            </Typography>
            <Grid container>
                <Grid item sm={6} xs={12}>
                <strong>For Visa, Master Card</strong><br />
                <img src={CreditCardCvcImage} alt="CVC for Visa, Master Card" style={{'width': '80%'}} /><br />
                <p>
                    The three-digit number is printed on the signature panel on the back of the card.
                </p>
                </Grid>
                <Grid item sm={6} xs={12}>
                <strong>For American Express</strong><br />
                <img src={CreditCardCvc2Image} alt="CVC for American Express Card" style={{'width': '80%'}} /><br />
                <p>
                    The four-digit code is printed on the front of the card.
                </p>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
    }
    // no plan -> urges to select plan
    // no payment -> 
    // under trial -> shows period
    // under free plan -> 
    render() {
        const { classes } = this.props;
        const { redirect } = this.state;
        const { discountPrice, card, nextPaymentDate, hasPaymentMethod } = this.state;
        if (redirect) {
            return  <Redirect to="/change-finish" >
                    </Redirect>
        }
        return (
            <DashboardTemplate maxWidth="md" prependItems={this.prependItems()} appendItems={this.appendItems()}>
                <div>
                    <CardContent>
                        <h3 className={classes.sectionTitle}>Current Payment Information</h3>
                        { hasPaymentMethod ? 
                            <div><p>
                                {card.brand}&nbsp;
                                **** **** **** {card.last4}&nbsp;
                                {card.exp_month}/{card.exp_year}
                            </p>
                            <p>
                                Next payment due:&nbsp;
                                {nextPaymentDate}
                            </p></div> :
                            <p> No payment set </p>
                    }
                        <h3 className={classes.sectionTitle}>Change Payment</h3>
                        <Typography variant="h5" component="h3" align="center">
                            Price for your slack workspace will be:<br />
                            <span class={classes.calculatedPrice}>
                                ${discountPrice} per month
                            </span>
                            { this.paymentAlert() }
                        </Typography>
                        { this.paymentForm() }
                        <p align="center">
                            *If you prefer a 12-months advance payment by invoice, please talk to us by chat or hello@kiara.team.
                        </p>
                    </CardContent>
                    <MixpanelOnload message="kiara-app.com/setup-payment" />
                </div>
            </DashboardTemplate>
        )
    }
}
export default withStyles(useStyles)(SetupPayment);
