import React, {Component} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';

import logo from '../../img/kiara-logo-2020.png'

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
  },
  link: {
    marginLeft: '2px',
  },
  appBar: {
    backgroundColor:'white',
    boxShadow:'none',
    MuiButton: {
      textTransform: 'none',
    },
    Button: {
      textTransform: 'none',
    },
  }
});

class HeaderBar extends Component {
  render () {
    const { classes } = this.props;
    const {children} = this.props;
  
    return (
      <Container component="main" className={classes.root}>
        <CssBaseline />
        <AppBar position="static" className={classes.appBar} sx={{ background: 'white' }}>
          <Toolbar className={classes.toolbar}>
            <Link to='/' className={classes.logo}>
              <img src={logo} alt="Kiara Inc" height="36" width="auto"/>
            </Link>
            {children}
          </Toolbar>
        </AppBar>
      </Container>
    );      
  }
}

export default withStyles(useStyles)(HeaderBar);
