import React from "react";
import { withStyles } from '@mui/styles';

import SignInTemplate from "../components/templates/SignInTemplate";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
//import Button from "@mui/material/Button";
import MixpanelOnload from "../components/MixpanelOnload";
import MixpanelButton from "../components/MixpanelButton";
//import MixpanelIconButton from "../components/MixpanelIconButton";

const useStyles = theme => ({
    '@global': {
    },
});

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            //loading: true,
            //client_id: '',
        };
    }

    componentDidMount() {
    }


    render() {
        //const slack_url = "https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team&client_id=" + client_id + "&state=production";
        //const bypassOauth = "http://localhost:8081/api/oauth/bypass";
        //const url = process.env.NODE_ENV === 'development' ? bypassOauth : slack_url;
        return (
            <SignInTemplate
                title="Sign in">
                <CardActions>
                    <MixpanelButton mixpanelMessage="kiara_app_com_signin_auth_by_slack_button" href="/api/slack/signIn" size="small" color="primary">
                        <img alt="Sign in by Slack" src="https://platform.slack-edge.com/img/sign_in_with_slack@2x.png" width="139" />
                    </MixpanelButton>
                </CardActions>
                <CardContent>
                    <p>
                        Sign in to your team's Slack workspace
                    </p>
                </CardContent>
                <CardActions>
                    <MixpanelButton mixpanelMessage="kiara_app_com_signin_need_support" href="https://www.getkiara.com/help" size="small" color="primary">
                        Need support?
                    </MixpanelButton>
                </CardActions>
                <MixpanelOnload message="kiara-app.com/sign-in" />
            </SignInTemplate>
        )
    }
}
export default withStyles(useStyles)(SignIn);