import React from "react";
import SignInTemplate from "../components/templates/SignInTemplate";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
//import Button from "@mui/material/Button";
import MixpanelOnload from "../components/MixpanelOnload";

export class RedirectOk extends React.Component {
  render() {
    return (
      <SignInTemplate title="Redirect OK">
        <CardContent>
          <p className="header">
            Success!
            <br/>
          </p>
          <p>
              Please sign in using appropriate Slack workspace.
          </p>
        </CardContent>
        <CardActions>
          <strong>
            Please invite 「Kiara Translation」bot to channel where you can translate!
          </strong>
        </CardActions>
        <MixpanelOnload message="kiara-app.com/redirect-ok" />
      </SignInTemplate>
    )
  }
}
