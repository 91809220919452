import React from "react";
import { withStyles } from '@mui/styles';

import SignUpTemplate from "../components/templates/SignUpTemplate";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
//import Button from "@mui/material/Button";
import SignUpSteps from "../components/SignUpSteps";
import MixpanelOnload from "../components/MixpanelOnload";
import MixpanelButton from "../components/MixpanelButton";
//import MixpanelIconButton from "../components/MixpanelIconButton";
import SignUpApproveGif from '../img/screenshots/sign-up-approve.gif';

const useStyles = theme => ({
    '@global': {
    },
    
});

class SignUp extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
    }


    render() {
        const slack_url = "/api/slack/install";
        const bypassOauth = "http://localhost:3000/api/oauth/bypass_add";
        const url = process.env.NODE_ENV === 'development' ? bypassOauth : slack_url;
        console.log(process.env);
        const activeStep = 0;
        return (
            <SignUpTemplate>
                <SignUpSteps activeStep={activeStep} />
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <CardActions>
                    <MixpanelButton mixpanelMessage="kiara_app_com_signup_add_slack" href={url}>
                        <img alt="Add to Slack" src="https://platform.slack-edge.com/img/add_to_slack@2x.png" width="139" />
                    </MixpanelButton>
                </CardActions>
                <CardContent>
                    <p>
                        Sign up to your team's Slack workspace
                    </p>
                </CardContent>
                <CardActions>
                    <MixpanelButton mixpanelMessage="kiara_app_com_signup_learn_more" href="https://www.getkiara.com/help" size="small" color="primary">
                        Learn More
                    </MixpanelButton>
                </CardActions>
                <img src={SignUpApproveGif} alt="how to approve" style={{'width': '100%'}} /><br />
                <MixpanelOnload message="kiara-app.com/sign-up" />
            </SignUpTemplate>
        )
    }
}
export default withStyles(useStyles)(SignUp);