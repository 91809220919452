import React, {Component} from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
//import LockOpenIcon from '@mui/icons-material/LockOpen';
//import CreateIcon from '@mui/icons-material/Create';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
//import Drawer from '@mui/material/Drawer';
import MixpanelButton from "../MixpanelButton";
//import MixpanelIconButton from "../MixpanelIconButton";

import Footer from './Footer';
import HeaderBar from './HeaderBar';
import { MixpanelProvider } from 'react-mixpanel';
import mixpanel from '../../utils/mixpanel';

const useStyles = (theme) => (
  {
  root : {
    marginTop: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textTransform: 'capitalize',
  },
  signUp: {
    backgroundColor: '#ff2f05',
    color: 'white',
  }
});

class SignInTemplate extends Component {
  render () {
    const { classes } = this.props;
    const { title, children} = this.props;
    const { prependItems, appendItems } = this.props;  
    let { maxWidth } = this.props;
    if (maxWidth == null) {
      maxWidth = 'xs';
    }

    const addTitle = () => {
      if (title) {
        return  <>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        </>
      }
      return null;
    }

    return (
      <MixpanelProvider mixpanel={mixpanel}>
        <CssBaseline />
        <HeaderBar className={classes.appBar}>
          <Box component="span" display={{ xs: 'none', md: 'inline' }}>
            <MixpanelButton mixpanelMessage="kiara_app_com_header_menu_features" href="https://www.getkiara.com/?mode=sk#features" color="secondary" aria-label="Features" size="small" className={classes.link}>
              Features
            </MixpanelButton>
          </Box>
          <Box component="span" display={{ xs: 'none', md: 'inline' }}>
            <MixpanelButton mixpanelMessage="kiara_app_com_header_menu_customers" href="https://www.getkiara.com/customers" color="secondary" aria-label="Customers" size="small" className={classes.link}>
              Customers
            </MixpanelButton>
          </Box>
          <Box component="span" display={{ xs: 'none', md: 'inline' }}>
            <MixpanelButton mixpanelMessage="kiara_app_com_header_menu_pricing" href="https://www.getkiara.com/?mode=sk#pricing" color="secondary" aria-label="Pricing" size="small" className={classes.link}>
              Pricing
            </MixpanelButton>
          </Box>
          <Box component="span" display={{ xs: 'none', md: 'inline' }}>
            <MixpanelButton mixpanelMessage="kiara_app_com_header_menu_help" href="https://www.getkiara.com/help" color="secondary" aria-label="Help" size="small" className={classes.link}>
              Help
            </MixpanelButton>
          </Box>
          <Box component="span" display={{ xs: 'none', md: 'inline' }}>
            <MixpanelButton mixpanelMessage="kiara_app_com_header_menu_sign-in" href="/sign-in" color="secondary" aria-label="Login" size="small" className={classes.link}>
              Login
            </MixpanelButton>
          </Box>
          <Box component="span" display={{ xs: 'none', md: 'inline' }}>
            <MixpanelButton mixpanelMessage="kiara_app_com_header_menu_sign-up" href="/sign-up" variant="contained" aria-label="Sign up" className={[classes.link, classes.signUp]}>
              Sign up
            </MixpanelButton>
          </Box>
        </HeaderBar>
        <Container component="main" maxWidth={maxWidth} className={classes.root}>
          <Box className={classes.extraItems}>
            {prependItems}
          </Box>
          <Card className={classes.paper}>
            {addTitle()}
            {children}
          </Card>
          <Box className={classes.extraItems}>
            {appendItems}
          </Box>
        </Container>
        <Footer />
      </MixpanelProvider>
    );
  }
}

export default withStyles(useStyles)(SignInTemplate);