import React, { Component } from 'react';
import Select from "react-select";
import { DashboardLayoutCss, DashboardMainCss} from '../../containers/admin-dashboard/styles/main';
import GA from '../../utils/GoogleAnalytics'
const Checkbox = props => <input type="checkbox" {...props} />;


export default class SendDM extends Component {
    constructor(props){
        super(props);
        this.state = {
            message: '',
            messageSent: false,
            isDisabledTeamIds: true,
            teamIds: [],
            selectedTeamId: []
        };
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.toggleDisabledTeamIds = this.toggleDisabledTeamIds.bind(this);
        this.handleTeamIdChange = this.handleTeamIdChange.bind(this);
    }

    componentDidMount() {
        fetch("/api/admin/slack/dm/team-id-list", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => {
            let teamIdOptions = data.teamIdList.map((teamId) => ({
                value: teamId, label: teamId
            }));
            this.setState({teamIds: teamIdOptions});
        })
        
    }

    handleMessageChange(event) {
        this.setState({message: event.target.value});
    }

    handleTeamIdChange(options) {
        let selectedOptions = options.map((option) => {
            return option.value
        })
        this.setState({selectedTeamId: selectedOptions});
    }

    toggleDisabledTeamIds() {
        this.setState({isDisabledTeamIds: !this.state.isDisabledTeamIds})
    }

    submit = event => {
        event.preventDefault();

        if (this.state.isDisabledTeamIds) {

            const confirm = window.confirm("Are you sure want to send message to all channels?");
            
            if (confirm) {
                GA.Event('Admin-DM', 'send dm to all', 'AdminDashboard');
                fetch("/api/admin/slack/dm", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'message': this.state.message
                    })
                }).then((response) => {
                    if (response.ok) {
                        this.setState({messageSent: true});
                        this.setState({message: ''});
                    }
                })
            }
        } else {
            const confirm = window.confirm("Are you sure want to send message to selected channels?");
            
            if (confirm) {
                GA.Event('Admin-DM', 'send dm to specific team', 'AdminDashboard');
                fetch("/api/admin/slack/dm-team", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'message': this.state.message,
                        'team_id_list': this.state.selectedTeamId
                    })
                }).then((response) => {
                    if (response.ok) {
                        this.setState({messageSent: true});
                        this.setState({message: ''});
                    }
                })
            }
        }
    }

    render() {

        const {isDisabledTeamIds, teamIds} = this.state;
          
        return (
            <div className={DashboardMainCss + ' ' + DashboardLayoutCss}>
                
                <div className="form-content">
                    <div className="header">
                        <h2>
                        Send Direct Message to all registered channels
                        </h2>
                        {
                            this.state.messageSent ? (
                                <h3>Message is sent!</h3>
                            ) : (
                                <br />
                            )
                        }
                    </div>

                    <div className="form-content-inner">
                        <div className='Stripe'>
                        
                            <div className="user-form">

                                <div className="user-form-email">
                                        <label>
                                            <Checkbox 
                                                checked={!isDisabledTeamIds}
                                                onChange={this.toggleDisabledTeamIds}
                                            /> Send to selected Team ID(s)
                                            <br />
                                        
                                        </label>
                                        
                                        <Select 
                                            options={teamIds} 
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={isDisabledTeamIds}
                                            onChange={this.handleTeamIdChange}
                                        />
                                    
                                </div>
                                <br />
                                <div className="user-form-email">
                                    <label>Message</label>
                                    
                                        <textarea type="message" value={this.state.message} onChange={this.handleMessageChange} id="message" name="message" placeholder="Your message" required="" ></textarea>
                                    
                                </div>
                            </div>

                            <div className="form-btn">
                                <button className="btn btn-default" onClick={this.submit}>Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
