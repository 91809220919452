import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

import DashboardTemplate from "../components/templates/DashboardTemplate";
import MixpanelOnload from "../components/MixpanelOnload";

const useStyles = theme => ({
    expandableCardTitle: {
        fontSize: '21px',
        fontWeight: 'bold',
        lineHeight: '24px',
        marginBottom: '8px',
    },
    expandableCardSubTitle: {
        fontSize: '14px',
        color: '#888888',
    },    
});

class Billing extends React.Component {
    constructor() {
        super();
        this.state = {
            invoices: []
        }
    }

    componentDidMount() {
        fetch('/api/stripe/invoices', {
            method: "POST"
        })
        .then(res => {
            if (res.status !== 200) {
                throw new Error('bad request');
            } else {
                return res.json();
            }
        }).then(data => {
            this.setState({invoices: data.invoices})
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        const {classes} = this.props;
        const {invoices} = this.state;
        // to grid layout?
        return (
            <DashboardTemplate>
                <Typography variant="h5" component="h2">
                    Billing History Page
                </Typography>
                <List component="nav" aria-label="billing list">
                {
                    invoices.map((invoice, i) => (
                        <ListItem key={i} className={classes.billingPage}>
                            <ListItemText className={classes.expandableCardTitle}>
                                Receipt #{invoice.receipt_number}
                            </ListItemText>
                            <ListItemText className={classes.expandableCardSubTitle}>
                                <p>Amount Paid: {invoice.currency === 'usd' ? '$' : invoice.currency} {invoice.amount / 100}</p>
                                <p>Payment Date: {invoice.payment_date}</p>
                                <p>Card: {invoice.card.brand + ' - ' + invoice.card.last4}</p>
                                <p><a href={invoice.receipt_url}>Download Receipt</a></p>
                            </ListItemText>
                        </ListItem>
                    ))
                }
                </List>
                {
                    (invoices.length === 0) ?
                    <p>No Billing Data</p> :
                    <p></p>
                }
                <MixpanelOnload message="kiara-app.com/billing" />
            </DashboardTemplate>
        );

    }
}

export default withStyles(useStyles)(Billing);