import React from "react";
import SendDM from '../components/admin-dashboard/SendDM';

import DashboardTemplate from "../components/templates/DashboardTemplate";
import MixpanelOnload from "../components/MixpanelOnload";

export class AdminDashboard extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {
        
    }

    render() {
        
        return (
            <DashboardTemplate>
                <div className="container form-container">
                    <div className="css-kxp0xy">
                        <div className="example">
                            <div className="current_plan">
                                <div className="left">
                                    <span>Hi Admin!</span>
                                </div>
                                <br />
                            </div>
                            <SendDM />
                        </div>
                    </div>
                </div>
            </DashboardTemplate>
        )
    }
}
