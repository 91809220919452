import React from "react";
import { withStyles } from '@mui/styles';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { StepIconProps } from '@mui/material/StepIcon';

// TODO change to SVG or other format
import step1ImageActive from '../img/steps/step1_red.png';
import step2ImageActive from '../img/steps/step2_red.png';
import step3ImageActive from '../img/steps/step3_red.png';
import step4ImageActive from '../img/steps/step4_red.png';
import step5ImageActive from '../img/steps/step5_red.png';
import step1Image from '../img/steps/step1_gray.png';
import step2Image from '../img/steps/step2_gray.png';
import step3Image from '../img/steps/step3_gray.png';
import step4Image from '../img/steps/step4_gray.png';
import step5Image from '../img/steps/step5_gray.png';

// TODO change icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SyncIcon from '@mui/icons-material/Sync';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';//TODO LoginTo in mui 5
//import step3Icon from '../img/steps/step3_icon.png';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PaymentIcon from '@mui/icons-material/Payment';

const useStyles = theme => ({
    '@global': {
    },
    'stepLabel': {
        'textAlign': 'center',
        'fontSize': '10px',
        'padding': '0px 0px',
        'margin': '10px 0px',
    },
    'stepIcon': {
        'fontSize': '40px',
    }
});

// reference https://material-ui.com/components/steppers/
class SignUpSteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openStep1: false,
            openStep2: false,
        };
    }
    stepIcons(props) {
        const { active, completed } = props;
        console.log(active)
        const inActiveIcons: { [index] : React.ReactElement } = { //
            1: <img alt="1" src={step1Image} width="60" />,
            2: <img alt="2" src={step2Image} width="60" />,
            3: <img alt="3" src={step3Image} width="60" />,
            4: <img alt="4" src={step4Image} width="60" />,
            5: <img alt="5" src={step5Image} width="60" />,
        };
        const activeIcons: { [index] : React.ReactElement } = { //
            1: <img alt="1" src={step1ImageActive} width="60" />,
            2: <img alt="2" src={step2ImageActive} width="60" />,
            3: <img alt="3" src={step3ImageActive} width="60" />,
            4: <img alt="4" src={step4ImageActive} width="60" />,
            5: <img alt="5" src={step5ImageActive} width="60" />,
        };
        const icons = active ? activeIcons : inActiveIcons;
        return (
            <div>
                {icons[String(props.icon)]}
            </div>
        );
    }
    step1ImageBox() {
        return <Typography>
            Authorize app to access your Slack workspace
            <img src={step1Image} alt="step1" style={ { display: "block", maxWidth: "290px" }} />
        </Typography>
    }
    step2ImageBox() {
        return <Typography>
            Authorize app to access your Slack user info
            <img src={step1Image} alt="step2" style={ { display: "block", maxWidth: "290px"  }}/>
        </Typography>
    }
    step3ImageBox() {
        return <Typography>
            Authorize app to access your Slack user info
            <img src={step3Image} alt="step3" style={ { display: "block", maxWidth: "290px"  }}/>
        </Typography>
    }
    step4ImageBox() {
        return <Typography>
            Authorize app to access your Slack user info
            <img src={step4Image} alt="step4" style={ { display: "block", maxWidth: "290px"  }}/>
        </Typography>
    }
    step5ImageBox() {
        return <Typography>
            <img src={step5Image} alt="step5" style={ { display: "block", maxWidth: "290px"  }}/>
            <p>Card Info</p>
        </Typography>
    }
    op (item) {
        alert(item);
    }
    render() {
        const steps = [
            { text: 'Add to Slack', image: step1Image, activeImage: step1ImageActive, icon: AddCircleOutlineIcon, popup: this.step1ImageBox }, 
            { text: 'Authorize', image: step2Image, activeImage: step2ImageActive, icon: SyncIcon, popup: this.step2ImageBox },
            { text: 'Login', image: step3Image, activeImage: step3ImageActive, icon: ExitToAppIcon, popup: this.step3ImageBox },
            { text: 'Count user', image: step4Image, activeImage: step4ImageActive, icon: PeopleAltOutlinedIcon, popup: this.step4ImageBox },
            { text: 'Card info', image: step5Image, activeImage: step5ImageActive, icon: PaymentIcon, popup: this.step5ImageBox },
        ];
        const { activeStep, classes } = this.props;
        return (
            <React.Fragment>
                <Stepper activeStep={activeStep} connector={null} alternativeLabel>
                    {steps.map((step) => (
                    <Step key={step.text}>
                        <StepLabel StepIconComponent={this.stepIcons}>
                            { 'image' in step ? 
                            //<Tooltip title={step.popup()}>
                                <Typography>
                                    <p className={classes.stepLabel}>{step.text}</p>
                                    <Typography component={step.icon} className={classes.stepIcon} />
                                </Typography>
                            //</Tooltip>
                            :
                            <Typography>{step.text}</Typography>
                        }
                        </StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </React.Fragment>
        )
    }
}
export default withStyles(useStyles)(SignUpSteps);