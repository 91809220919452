import React from "react";
import TextField from '@mui/material/TextField';
import { MixpanelConsumer } from 'react-mixpanel';

/* This component overrides Material UI Button component
 * and enable to send tracking message to mixpanel
 * additional parameters
 * - mixpanelMessage: message(event) to send
 * - mixpanelTrack: original track function for sending message (e, mixpanel, message)
 */
class MixpanelTextField extends React.Component {
    onClick=null;
    onChange=null;
    clickWithTracking = (e, mixpanel) => {
        if (this.props.mixpanelTrack) {
            this.props.mixpanelTrack(e, mixpanel, this.props.mixpanelMessage);
        }
        else {
            mixpanel.track(this.props.mixpanelMessage);
        }
        if (this.onClick) {
            return this.onClick(e);
        }
        return;
    }
    changeWithTracking = (e, mixpanel) => {
        console.log(e);
        let message = this.props.mixpanelMessage;
        const params = {}
        if (e.error) {
            message += '_error';
            params['error'] = e.error
        }
        if (this.props.mixpanelTrack) {
            this.props.mixpanelTrack(e, mixpanel, message, params);
        }
        else {
            mixpanel.track(message, params);
        }
        if (this.onChange) {
            return this.onChange(e, mixpanel);
        }
        return;
    }
	render() {
        const propsCopy = {};
        Object.assign(propsCopy, this.props);
        this.onClick = this.props.onClick
        this.onChange = this.props.onChange
        if (propsCopy.hasOwnProperty('onClick')) {
            delete propsCopy['onClick'];
        }
        if (propsCopy.hasOwnProperty('onChange')) {
            delete propsCopy['onChange'];
        }
        return <MixpanelConsumer>
            { mixpanel => <TextField {...propsCopy} onClick={ e => this.clickWithTracking(e, mixpanel) } onChange={ e => this.changeWithTracking(e, mixpanel) }/>}
        </MixpanelConsumer>
    }
}
export default MixpanelTextField;