import React from "react";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { withStyles } from '@mui/styles';

import DashboardTemplate from "../components/templates/DashboardTemplate";
import MixpanelOnload from "../components/MixpanelOnload";

//import PaymentIcon from '@mui/icons-material/PaymentIcon';
import KiaraLogo from '../img/kiara_logo_icon_red.png';
//const moment = require('moment');

const useStyles = theme => ({
    '@global': {
    },
    paper: {
        padding: '8px',
    }
});

class ChangeFinish extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
        //this.getAccount();
    }

    prependItems = () => {
        return <div />
    }
    appendItems = () => {
        return <div />
    }

    render() {
        // TODO change trial finish date
        return (
            <DashboardTemplate maxWidth="xl" prependItems={this.prependItems()} appendItems={this.appendItems()}>
                <div>
                    <CardContent align="center">
                        <img src={KiaraLogo} alt="kiara logo" style={{'width': '120px'}} />
                        <Typography variant="h6" component="p">
                            Your change has been completed.
                        </Typography>
                        <p>
                        Your change succeeded.<br /><br />
                        Need support? Please check out our <a href="https://www.getkiara.com/help/category/faq">FAQ</a>.<br />
                        For more information, talk to us by chat, <a href="https://www.getkiara.com/contact">form</a> or to hello@kiara.team
                        </p>
                    </CardContent>
                    <MixpanelOnload message="kiara-app.com/change-finish" />
                </div>
            </DashboardTemplate>
        )
    }
}
export default withStyles(useStyles)(ChangeFinish);
